/* Variables */

//window size
$breakpoint-desktop: 781px;
$breakpoint-tablet: 1200px;
$breakpoint-mobile: 780px;
$breakpoint-mobile-extreme: 320px;

$vh: var(--vh, 1vh);
$vh100: calc(#{$vh} * 100);

////main-font
$main-font: 'Pretendard';

//font-size
$font-h1: 56px;
$font-h2: 48px;
$font-h3: 36px;
$font-h4: 32px;
$font-h5: 24px;
$font-h6: 20px;
$font-h7: 17px;
$font-h8: 28px;
$font-p: 15px;
$font-small: 13px;
$font-small2: 11px;

//font-weight
$font-bold: 700;
$font-semi-bold: 600;
$font-medium: 500;
$font-regular: 400;
$font-light: 300;


//line-height
$line-height-base: 1.6;
$line-height-adjust: 1.3;

//color
$color-white: #FFFFFF;
$color-black: #000000;

$color-primary-50: #F4FBFB;
$color-primary-100: #EBFAF8;
$color-primary-200: #9CF0E2;
$color-primary-300: #33D8BE;
$color-primary-400: #1AC6AC;
$color-primary-500: #09AD97;
$color-primary-600: #008F7F;
$color-primary-700: #006C63;
$color-primary-800: #005A54;
$color-primary-900: #004744;

$color-primary-base: $color-primary-800;
$color-primary-accent: $color-primary-500;

$color-skin-dark: #FFCB9C;
$color-skin-base: #FFEFE1;
$color-skin-light: #FFF5EB;

$color-gray-50: #F8F9FA;
$color-gray-100: #F1F3F5;
$color-gray-200: #E9ECEF;
$color-gray-300: #DEE2E6;
$color-gray-400: #CCD2D8;
$color-gray-500: #AEB5BA;
$color-gray-600: #888E94;
$color-gray-650: #646A70;
$color-gray-700: #484F54;
$color-gray-750: #3A3E43;
$color-gray-800: #32363A;
$color-gray-850: #26282B;
$color-gray-900: #1F2022;
$color-gray-950: #17181A;

$legacy-color-gray-50: #FBFAF9;
$legacy-color-gray-100: #F6F4F2;
$legacy-color-gray-200: #EBE9E5;
$legacy-color-gray-300: #DBD7D1;
$legacy-color-gray-400: #C1BAB0;
$legacy-color-gray-500: #A1988B;
$legacy-color-gray-600: #84796B;
$legacy-color-gray-700: #685B4E;
$legacy-color-gray-800: #4B4133;
$legacy-color-gray-900: #282019;

$legacy-color-gray-opacity-50: rgba(51, 29, 0, 0.02);
$legacy-color-gray-opacity-100: rgba(71, 44, 2, 0.05);
$legacy-color-gray-opacity-200: rgba(55, 34, 0, 0.1);
$legacy-color-gray-opacity-300: rgba(58, 31, 0, 0.18);
$legacy-color-gray-opacity-400: rgba(54, 33, 0, 0.31);
$legacy-color-gray-opacity-500: rgba(50, 32, 3, 0.46);
$legacy-color-gray-opacity-600: rgba(43, 25, 0, 0.58);
$legacy-color-gray-opacity-700: rgba(40, 24, 3, 0.7);
$legacy-color-gray-opacity-800: rgba(30, 16, 0, 0.8);
$legacy-color-gray-opacity-900: rgba(19, 11, 2, 0.91);

$color-gold-50: #FCFAF7;
$color-gold-100: #F7F0E4;
$color-gold-200: #EBDBC3;
$color-gold-300: #E3CBA8;
$color-gold-400: #DBBD91;
$color-gold-500: #CFB082;
$color-gold-600: #BD9F71;
$color-gold-700: #9E7C49;
$color-gold-800: #795725;
$color-gold-900: #604111;

$color-blue-50: #FCFDFF;
$color-blue-100: #F5FAFF;
$color-blue-200: #EBF1FF;
$color-blue-300: #E1E8FC;
$color-blue-400: #D2DEF7;
$color-blue-500: #BCCFF7;
$color-blue-600: #97B4F7;
$color-blue-700: #6F98F7;
$color-blue-800: #5783F2;
$color-blue-900: #2C57BE;

$color-sub-blue: #415FD9;
$color-sub-navy: #19275F;

// 로앤굿 2.0 색
$color-28282D: #28282D;
$color-3B3B42: #3B3B42;
$color-46464F: #46464F;
$color-5D5D6C: #5D5D6C;
$color-89898E: #89898E;
$color-BABABD: #BABABD;
$color-EAEAEA: #EAEAEA;
$color-F6F6F6: #F6F6F6;

$color-FFFAF6: #FFFAF6;
$color-435D5B: #435D5B;
$color-295240: #295240;

$color-C09266: #C09266;
$color-217C9A: #217C9A;


//element
$nav-height: 80px;
$nav-mobile-height: 54px;

//shadow
$shadow-small: 0 0 4px 0 $legacy-color-gray-opacity-100, 0 4px 16px 0 $legacy-color-gray-opacity-100;
$shadow-medium: 0 8px 16px 0 $legacy-color-gray-opacity-100, 0 4px 8px 0 $legacy-color-gray-opacity-100;
$shadow-large: 0 24px 40px 0 $legacy-color-gray-opacity-100, 0 0 8px 0 $legacy-color-gray-opacity-100;

//radius
$radius-small: 4px;
$radius-medium: 8px;
$radius-large: 16px;
$radius-xlarge: 20px;
$radius-xxlarge: 24px;

/* End: Variables */

/* Start: Typography */
@mixin f($s: false, $w: false, $ls:false, $lh: false, $c: false,) {
  @if $s {
    font-size: unquote($s + 'px');
  }

  @if $w {
    font-weight: $w;

    @if $w >= 600 {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  @if $c {
    color: $c;
  }

  @if $ls {
    $str-ls: quote($ls + '');

    @if (str-index($str-ls, 'em')) {
      letter-spacing: $ls;
    } @else {
      letter-spacing: unquote($ls + 'px');
    }
  }

  @if $lh {
    line-height: unquote($lh + 'px');
  }
}

@mixin display-38-bold($color: false) {
  @include f($s: 38, $w: 700, $ls: 0, $lh: 50, $c: $color);
}

@mixin display-32-bold($color: false) {
  @include f($s: 32, $w: 700, $ls: 0, $lh: 42, $c: $color);
}

@mixin display-28-bold($color: false) {
  @include f($s: 28, $w: 700, $ls: 0, $lh: 40, $c: $color);
}

@mixin display-24-bold($color: false) {
  @include f($s: 24, $w: 700, $ls: 0, $lh: 34, $c: $color);
}

@mixin display-22-bold($color: false) {
  @include f($s: 22, $w: 700, $ls: 0, $lh: 32, $c: $color);
}

@mixin heading-20-bold($color: false) {
  @include f($s: 20, $w: 700, $ls: 0, $lh: 28, $c: $color);
}

@mixin heading-18-bold($color: false) {
  @include f($s: 18, $w: 700, $ls: 0, $lh: 26, $c: $color);
}

@mixin heading-16-bold($color: false) {
  @include f($s: 16, $w: 700, $ls: 0, $lh: 24, $c: $color);
}

@mixin heading-15-bold($color: false) {
  @include f($s: 15, $w: 700, $ls: 0, $lh: 22, $c: $color);
}

@mixin heading-14-bold($color: false) {
  @include f($s: 14, $w: 700, $ls: 0, $lh: 20, $c: $color);
}

@mixin heading-13-bold($color: false) {
  @include f($s: 13, $w: 700, $ls: 0, $lh: 18, $c: $color);
}

@mixin heading-12-bold($color: false) {
  @include f($s: 12, $w: 700, $ls: 0, $lh: 16, $c: $color);
}

@mixin heading-20-semi-bold($color: false) {
  @include f($s: 20, $w: 600, $ls: 0, $lh: 28, $c: $color);
}

@mixin heading-18-semi-bold($color: false) {
  @include f($s: 18, $w: 600, $ls: 0, $lh: 26, $c: $color);
}

@mixin heading-16-semi-bold($color: false) {
  @include f($s: 16, $w: 600, $ls: 0, $lh: 24, $c: $color);
}

@mixin heading-15-semi-bold($color: false) {
  @include f($s: 15, $w: 600, $ls: 0, $lh: 22, $c: $color);
}

@mixin heading-14-semi-bold($color: false) {
  @include f($s: 14, $w: 600, $ls: 0, $lh: 20, $c: $color);
}

@mixin heading-13-semi-bold($color: false) {
  @include f($s: 13, $w: 600, $ls: 0, $lh: 18, $c: $color);
}

@mixin heading-13-medium($color: false) {
  @include f($s: 13, $w: 500, $ls: 0, $lh: 18px, $c: $color)
}

@mixin heading-13-regular($color: false) {
  @include f($s: 13, $w: 400, $ls: 0, $lh: 18px, $c: $color)
}

@mixin heading-12-semi-bold($color: false) {
  @include f($s: 12, $w: 600, $ls: 0, $lh: 16, $c: $color);
}

@mixin heading-18-medium($color: false) {
  @include f($s: 18, $w: 500, $ls: 0, $lh: 26, $c: $color);
}

@mixin heading-16-medium($color: false) {
  @include f($s: 16, $w: 500, $ls: 0, $lh: 24, $c: $color);
}

@mixin heading-15-medium($color: false) {
  @include f($s: 15, $w: 500, $ls: 0, $lh: 22, $c: $color);
}

@mixin heading-14-medium($color: false) {
  @include f($s: 14, $w: 500, $ls: 0, $lh: 20, $c: $color);
}

@mixin heading-13-medium($color: false) {
  @include f($s: 13, $w: 500, $ls: 0, $lh: 18, $c: $color);
}

@mixin heading-12-medium($color: false) {
  @include f($s: 12, $w: 500, $ls: 0, $lh: 16, $c: $color);
}

@mixin heading-20-regular($color: false) {
  @include f($s: 20, $w: 400, $ls: 0, $lh: 28, $c: $color);
}

@mixin heading-18-regular($color: false) {
  @include f($s: 18, $w: 400, $ls: 0, $lh: 26, $c: $color);
}

@mixin heading-16-regular($color: false) {
  @include f($s: 16, $w: 400, $ls: 0, $lh: 24, $c: $color);
}

@mixin heading-15-regular($color: false) {
  @include f($s: 15, $w: 400, $ls: 0, $lh: 22, $c: $color);
}

@mixin heading-14-regular($color: false) {
  @include f($s: 14, $w: 400, $ls: 0, $lh: 20, $c: $color);
}

@mixin heading-12-regular($color: false) {
  @include f($s: 12, $w: 400, $ls: 0, $lh: 16, $c: $color);
}

@mixin body-18-regular($color: false) {
  @include f($s: 18, $w: 400, $ls: -0.1, $lh: 34, $c: $color);
}

@mixin body-16-regular($color: false) {
  @include f($s: 16, $w: 400, $ls: -0.1, $lh: 28, $c: $color);
}

@mixin body-15-regular($color: false) {
  @include f($s: 15, $w: 400, $ls: -0.1, $lh: 26, $c: $color);
}

@mixin body-14-regular($color: false) {
  @include f($s: 14, $w: 400, $ls: -0.1, $lh: 24, $c: $color);
}

@mixin btn-18-bold($color: false) {
  @include f($s: 18, $w: 700, $ls: 0, $lh: 18, $c: $color);
}

@mixin btn-16-bold($color: false) {
  @include f($s: 16, $w: 700, $ls: 0, $lh: 16, $c: $color);
}

@mixin btn-14-bold($color: false) {
  @include f($s: 14, $w: 700, $ls: 0, $lh: 14, $c: $color);
}

@mixin btn-16-medium($color: false) {
  @include f($s: 16, $w: 500, $ls: 0, $lh: 16, $c: $color);
}

@mixin btn-14-medium($color: false) {
  @include f($s: 14, $w: 500, $ls: 0, $lh: 14, $c: $color);
}

@mixin btn-14-regular($color: false) {
  @include f($s: 14, $w: 400, $ls: 0, $lh: 14, $c: $color);
}

@mixin btn-16-regular($color: false) {
  @include f($s: 16, $w: 400, $ls: 0, $lh: 16, $c: $color);
}

@mixin btn-12-medium($color: false) {
  @include f($s: 12, $w: 500, $ls: 0, $lh: 12, $c: $color);
}

@mixin btn-12-regular($color: false) {
  @include f($s: 12, $w: 400, $ls: 0, $lh: 12, $c: $color);
}

@mixin btn-12-bold($color: false) {
  @include f($s: 12, $w: 700, $ls: 0, $lh: 12, $c: $color);
}


/* End: Typography */


/* Start: Functions */
/*
@usage
ex1. color: hex-to-rgba(#12345678);
ex2. color: hex-to-rgba(#42b883, 0.5);
ex3. color: hex-to-rgba(#333, 0.5);
 */
@function hex-to-rgba($hex, $opacity: '') {
  @if $opacity == '' {
    @if str-length(quote(#{$hex})) == 9 {
      $hex-opacity: str-slice(quote(#{$hex}), 8, 9);
      $opacity: 0;

      @for $i from 1 through 2 {
        $letter: str-slice($hex-opacity, $i, $i);
        $num: 0;

        @if $letter == '0' {
          $num: 0;
        } @else if $letter == '1' {
          $num: 1;
        } @else if $letter == '2' {
          $num: 2;
        } @else if $letter == '3' {
          $num: 3;
        } @else if $letter == '4' {
          $num: 4;
        } @else if $letter == '5' {
          $num: 5;
        } @else if $letter == '6' {
          $num: 6;
        } @else if $letter == '7' {
          $num: 7;
        } @else if $letter == '8' {
          $num: 8;
        } @else if $letter == '9' {
          $num: 9;
        } @else if $letter == 'A' {
          $num: 10;
        } @else if $letter == 'B' {
          $num: 11;
        } @else if $letter == 'C' {
          $num: 12;
        } @else if $letter == 'D' {
          $num: 13;
        } @else if $letter == 'E' {
          $num: 14;
        } @else if $letter == 'F' {
          $num: 15;
        }

        @if $i == 1 {
          $opacity: $opacity + $num * 16;
        } @else {
          $opacity: $opacity + $num;
        }
      }
      $opacity: round($opacity * 0.390625) * 0.01; // 0.390625 == 100 / 256
    } @else {
      $opacity: 1;
    }
  }
  @return rgba($hex, $opacity);
}

/* End: Functions */


/* Mixins */
@mixin desktop() {
  @media (min-width: $breakpoint-desktop) {
    & {
      @content;
    }
  }
}

@mixin tablet() {
  @media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    & {
      @content;
    }
  }
}

@mixin mobile() {
  @media (max-width: $breakpoint-mobile) {
    & {
      @content;
    }
  }
}

@mixin mobile-extreme() {
  @media (max-width: $breakpoint-mobile-extreme) {
    & {
      @content;
    }
  }
}

@mixin ie() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & {
      @content;
    }
  }
}

@mixin not-ie() {
  @supports not (-ms-high-contrast: none) {
    & {
      @content;
    }
  }
}

@mixin ios() {
  @supports (-webkit-touch-callout: none) {
    @content;
  }
}

@mixin no-drag() {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

@mixin no-arrow-select() {
  -webkit-appearance: none; /* for chrome */
  -moz-appearance: none; /*for firefox*/
  appearance: none;

  &::-ms-expand {
    display: none; /*for IE10,11*/
  }
}

/*
이미지를 감싸는 박스에 꽉 차도록 이미지를 늘리는 믹스인
세로로 늘어나야하면 $is-vertical true
가로로 늘어나야하면 false
 */
@mixin image-cover($parent: false, $is-vertical: false) {
  @include not-ie() {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @include ie() {
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);

    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    max-height: 100%;

    @if $is-vertical {
      max-width: 100%;
      min-height: 100%;
      min-width: auto;
      max-height: none;
    }
  }

  @if $parent {
    @at-root #{$parent} {
      position: relative;
      overflow: hidden;
    }
  }
}

/*
비율 박스 믹스인
$width: 가로 상대 크기
$height: 세로 상대 크기
$value: 기준 사이즈
$standard: 기준변 ('width' or 'height')

가로 혹은 세로를 기준변($standard)으로 잡고,
기준변($standard)의 사이즈를 기준사이즈($value)로 지정
기준변이 아닌 변의 사이즈는 $width, $height의 비율을 계산하여 지정
*/
@mixin ratio-box($width, $height, $value: 100vw, $standard: 'width') {
  @if $standard == 'width' {
    width: calc(#{$value});
    height: calc(#{$value} * #{$height} / #{$width});
  } @else if $standard == 'height' {
    height: calc(#{$value});
    width: calc(#{$value} * #{$width} / #{$height});
  }
}

//when: blue-box 사용할 때 (인자값: 넓이, 높이)
@mixin custom-box(
  $width,
  $height,
  $background-color: #28303B,
  $radius: 2px
) {
  border: none;
  background-color: $background-color;
  width: $width;
  height: $height;
  border-radius: $radius;
}

//when: scrollbar custom
@mixin custom-scrollbar($width, $bg-color: #EDEDED, $bar-color: #888, $bar-radius: 4px) {
  @if $width == 0 {
    & {
      -ms-overflow-style: none;
    }
  }

  &::-webkit-scrollbar {
    width: $width;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-track {
    background-color: $bg-color;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bar-color;
    border-radius: $bar-radius;
    cursor: pointer !important;
  }

  & {
    scrollbar-width: $width;
    scrollbar-color: $bar-color $bg-color;
    scrollbar-face-color: $bar-color;
    scrollbar-track-color: $bg-color;

    @content;
  }
}

@mixin custom-scrollbar-height($height, $bg-color: #EDEDED, $bar-color: #888, $bar-radius: 4px) {
  @if $height == 0 {
    & {
      -ms-overflow-style: none;
    }
  }

  &::-webkit-scrollbar {
    height: $height;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-track {
    background-color: $bg-color;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bar-color;
    border-radius: $bar-radius;
    cursor: pointer !important;
  }

  & {
    scrollbar-height: $height;
    scrollbar-color: $bar-color $bg-color;
    scrollbar-face-color: $bar-color;
    scrollbar-track-color: $bg-color;

    @content;
  }
}

@mixin sticky() {
  position: -webkit-sticky;
  position: sticky;
}

@mixin placeholder($color, $weight: false) {
  &::placeholder,
  &::-webkit-input-placeholder {
    color: $color;
    opacity: 1;

    @if $weight {
      font-weight: $weight;
    }
  }

  &:-ms-input-placeholder {
    color: $color;
    opacity: 1;

    @if $weight {
      font-weight: $weight;
    }
  }

  &:-mos-input-placeholder {
    color: $color;
    opacity: 1;

    @if $weight {
      font-weight: $weight;
    }
  }
}

//when: ellipsis 사용할 떄
@mixin ellipsis($line-num: 1) {
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line-num;
  word-break: break-word;
}

//when: flexbox 사용시
@mixin custom-flex($align, $justify, $direction: row, $wrap: wrap) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: $wrap;
}

//when: 너비가 flex에 의해 변형되지 않도록 절대 너비 설정
@mixin absolute-width($width) {
  max-width: $width;
  min-width: $width;
  width: $width;
}

/*
 $BG: 버퍼색,
 $BG-MOBILE: 모바일 버퍼 색,
 $DARK: 글자 색 (true: 글자 흰색, false: 글자 검은색),
 $BOX: 버튼 색,
 $BOX-HOVER: 버튼 호버 색,
 $BOX-TEXT: 버튼 글씨 색
*/
@mixin navigation($BG, $BG-MOBILE: false, $DARK: false, $BOX: #D0BDA0, $BOX-HOVER: #978B78, $BOX-TEXT: #26282B) {
  .buffer {
    background-color: $BG;

    @if $BG-MOBILE {
      @include mobile {
        background-color: $BG-MOBILE;
      }
    }
  }

  html > body > .navigation {
    .desktop {
      &.scrolled {
        background-color: white;
        border-bottom: 1px solid #E8EBED;
      }

      &:not(.scrolled) {
        .nav-side {
          .tabs {
            .tab {
              .text {
                @if $DARK {
                  color: white;
                }
              }

              &:not(.no-drag) {
                &:hover {
                  background-color: $color-EAEAEA;
                  @if $DARK {
                    background-color: rgba(255, 255, 255, 0.1);
                  }
                }
              }
            }
          }
        }

        @if $DARK {
          .left-nav {
            .logo {
              fill: white;
            }
          }
          .right-nav {
            .tabs {

              svg {
                fill: white;
              }

              .tab {
                color: white;

                &.box {
                  background-color: $color-skin-dark;

                  .text {
                    color: $color-435D5B;
                  }
                }
              }
            }

            .drop-box-toggle {
              .name {
                color: white;
              }

              .down {
                fill: white;
              }
            }
          }
        }
      }
    }

    .mobile {
      &.scrolled > .nav-container {
        background-color: white;
        border-bottom: 1px solid #E8EBED;
      }

      @if $DARK {
        &:not(.scrolled) > .nav-container {
          .mobile-nav-button {
            .text {
              background-color: $color-skin-dark;
              color: $color-435D5B;
            }
          }

          .mobile-nav-icon {
            svg {
              stroke: white;
            }

            &.toggle-inner-nav {
              svg {
                stroke: none;
                fill: white;
              }
            }
          }

          .logo-wrapper {
            .logo {
              fill: white;
            }
          }
        }
      }
    }
  }
}

@mixin custom-modal(
  $width,
  $height,
  $back-color: rgba(0, 0, 0, 0.7),
  $modal-color: white,
  $radius: 7px
) {
  @include custom-box(100vw, 100vh, $back-color, 0);
  @include custom-flex(center, center);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  &.dp-none {
    display: none;
  }

  .modal {
    @include custom-box($width, $height, $modal-color, $radius);
    min-height: 1px;
  }
}

@mixin circle($size, $bg-color: #FAFAFA) {
  width: $size;
  height: $size;
  border-radius: 100%;
  background-color: $bg-color;
}

@mixin profile-image($width) {
  @include circle($width, #C9CDD2);
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

// legacy
@mixin neulhan() {
  .medium {
    font-weight: 500;
  }
  .bold {
    font-weight: 700;
  }
  .blue {
    color: #3681FF;
  }
  .gold {
    color: #978B78;
  }
  .white {
    color: white;
  }
  .g50 {
    color: #F7F8F9;
  }
  .g100 {
    color: #E8EBED;
  }
  .g200 {
    color: #C9CDD2;
  }
  .g400 {
    color: #9EA4AA;
  }
  .g500 {
    color: #72787F;
  }
  .g600 {
    color: #454C53;
  }
  .g800 {
    color: #26282B;
  }

  .box {
    background-color: #F2F2F710;
    border-radius: 8px;
  }

  .dark {
    background-color: #29293188;
  }

  .light {
    background-color: #F2F2F788;
  }

  .sk {
    &.loaded {
      display: none;
    }
  }

  .box-title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 12px;
    color: #F2F2F7;
  }

  .p1 {
    padding: 12px;
  }

  .mb1 {
    margin-bottom: 12px;
  }

  .mb2 {
    margin-bottom: 24px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .t2 {
    width: 16%;
  }

  .t3 {
    width: 25%;
  }

  .t4 {
    width: 33.33%;
  }

  .t6 {
    width: 50%;
  }

  .t8 {
    width: 66.66%;
  }

  .t9 {
    width: 75%;
  }
  .t10 {
    width: 84%;
  }

  .t12 {
    width: 100%;
  }
}

/* End: Mixins */

/* start: Legacy Mixin */
//font-mixins
@mixin font-h3($color: #1B1D1F) {
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: $color;
}

@mixin font-h4($color: #1B1D1F) {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: $color;
}

@mixin font-h5($color: #1B1D1F) {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: $color;
}

@mixin font-h6($color: #1B1D1F) {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  color: $color;
}

@mixin font-body1($color: #26282B) {
  font-size: 16px;
  font-family: $main-font;
  line-height: 24px;
  color: $color;
}

@mixin font-body2($color: #26282B) {
  font-size: 14px;
  font-family: $main-font;
  line-height: 21px;
  color: $color;
}

@mixin font-button1($color: #26282B) {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $color;
}

@mixin font-button2($color: #26282B) {
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  color: $color;
}

@mixin font-button3($color: #26282B) {
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: $color;
}

@mixin font-caption1($color: #26282B) {
  font-size: 13px;
  font-family: $main-font;
  line-height: 20px;
  color: $color;
}

@mixin font-caption2($color: #26282B) {
  font-size: 12px;
  font-family: $main-font;
  line-height: 18px;
  color: $color;
}

//when: font style 적용시
@mixin font-text(
  $size,
  $color: false,
  $family: $main-font,
  $letter-spacing: 0,
  $line-height: normal,
) {
  @if $color {
    color: $color;
  }

  font-size: $size;
  font-family: $family;
  letter-spacing: $letter-spacing;
  line-height: $line-height;
}

// TYPO(FONT) MIXIN : updated_at 2020.12.01 Neulhan
@mixin typo-header-bold24($color: false) {
  @include font-text(24px, $color, $main-font, 0, 36px);
  font-weight: 700;
}

@mixin typo-sub-bold22($color: false) {
  @include font-text(22px, $color, $main-font, 0, 35px);
  font-weight: 700;
}

@mixin typo-sub-bold19($color: false) {
  @include font-text(19px, $color, $main-font, 0, 30px);
  font-weight: 700;
}

@mixin typo-sub-bold16($color: false) {
  @include font-text(16px, $color, $main-font, 0, 27px);
  font-weight: 700;
}

@mixin typo-sub-medium20($color: false) {
  @include font-text(20px, $color, $main-font, 0, 30px);
  font-weight: 500;
}

@mixin typo-sub-medium17($color: false) {
  @include font-text(17px, $color, $main-font, 0, 22px);
  font-weight: 500;
}

@mixin typo-body-regular17($color: false) {
  @include font-text(17px, $color, $main-font, 0, 30px);
}

@mixin typo-body-regular16($color: false) {
  @include font-text(16px, $color, $main-font, 0, 26px);
}

@mixin typo-body-regular15($color: false) {
  @include font-text(15px, $color, $main-font, 0, 25px);
}

@mixin typo-caption-medium15($color: false) {
  @include font-text(15px, $color, $main-font, 0, 25px);
  font-weight: 500;
}

@mixin typo-caption-medium14($color: false) {
  @include font-text(14px, $color, $main-font, 0, 22px);
  font-weight: 500;
}

@mixin typo-caption-regular10($color: false) {
  @include font-text(10px, $color, $main-font, 0, 21px);
}

/* end: Legacy Mixin */

