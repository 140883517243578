@import './_definitions.scss';

#kakao-channel-button {
  position: fixed;
  bottom: 56px;
  right: 44px;
  z-index: 100;

  padding: 16px 28px 16px 24px;
  border-radius: 8px;
  box-shadow: 0 4px 15px 0 rgba(7, 4, 114, 0.20);
  background-color: #19275F;
  cursor: pointer;

  @include custom-flex(center, center);

  @include mobile {
    bottom: 18px;
    right: 20px;

    padding: 10px;
  }

  .icon {
    @include custom-flex(center, center);
    width: 24px;
    height: 24px;
  }

  .text {
    @include heading-16-bold($color-white);
    display: block;
    margin-left: 2px;
  }
}