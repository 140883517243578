@import './definitions.scss';

:root {
  /* Overrides the border radius setting in the theme. */
  --ck-border-radius: 4px;

  /* Overrides the default font size in the theme. */
  --ck-font-size-base: 14px;

  ///* Helper variables to avoid duplication in the colors. */
  //--ck-custom-background: hsl(270, 1%, 29%);
  //--ck-custom-foreground: hsl(255, 3%, 18%);
  --ck-custom-border: #{$color-white};
  //--ck-custom-white: hsl(0, 0%, 100%);

  /* -- Overrides generic colors. ------------------------------------------------------------- */

  //--ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-focus-border: #{$color-white};
  --ck-color-text: #{$color-gray-700};
  --ck-color-engine-placeholder-text: #{$color-gray-600};
  --ck-color-shadow-drop: unset;
  --ck-color-shadow-inner: unset;

  /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

  //--ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-default-hover-background: #{$color-primary-50};
  //--ck-color-button-default-active-background: hsl(270, 2%, 20%);
  //--ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
  //--ck-color-button-default-disabled-background: var(--ck-custom-background);

  --ck-color-button-on-color: #{$color-primary-accent};
  --ck-color-button-on-background: #{$color-white};
  --ck-color-button-on-hover-background: #{$color-primary-50};
  --ck-color-button-on-active-background: #{$color-primary-100};
  //--ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
  //--ck-color-button-on-disabled-background: var(--ck-custom-foreground);

  //--ck-color-button-action-background: hsl(168, 76%, 42%);
  //--ck-color-button-action-hover-background: hsl(168, 76%, 38%);
  //--ck-color-button-action-active-background: hsl(168, 76%, 36%);
  //--ck-color-button-action-active-shadow: hsl(168, 75%, 34%);
  //--ck-color-button-action-disabled-background: hsl(168, 76%, 42%);
  //--ck-color-button-action-text: var(--ck-custom-white);
  //
  //--ck-color-button-save: hsl(120, 100%, 46%);
  //--ck-color-button-cancel: hsl(15, 100%, 56%);
  //

  /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--ck-custom-border);

  ///* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */
  //
  //--ck-color-widget-blurred-border: hsl(0, 0%, 87%);
  //--ck-color-widget-hover-border: hsl(43, 100%, 68%);
  //--ck-color-widget-editable-focus-background: var(--ck-custom-white);
  //
  ///* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */
  //
  //--ck-color-link-default: hsl(190, 100%, 75%);

  /* -- Etc Layout */
  --ck-spacing-standard: 0;
  --ck-spacing-small: 0;
  --ck-icon-size: 16px;
  --ck-ui-component-min-height: 20px;
  --ck-focus-ring: none;
}

.ck.ck-button, a.ck.ck-button {
  cursor: pointer;
  border: none;

  &:active, &:focus {
    outline: none;
  }
}

.ck.ck-toolbar__separator {
  background: $color-gray-200 !important;
}
