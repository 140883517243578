@import './_definitions.scss';


.buffer {
  height: $nav-height;
  width: 100%;
  @include mobile {
    height: $nav-mobile-height;
  }
}

.navigation {
  z-index: 28;

  #desktop-navigation {
    width: 100%;
    box-sizing: border-box;
    padding: 0 40px;
    background-color: transparent;
    z-index: 28;
    height: $nav-height;
    position: fixed;
    top: 0;
    left: 0;
    @include custom-flex(center, space-between);
    transition: background-color 0.3s;

    &.scrolled {
      background-color: white;
      border-bottom: 1px solid #E8EBED;
    }

    .nav-side {
      z-index: 100;
      position: relative;
      @include custom-flex(center, flex-start);

      .logo-wrapper {
        @include custom-flex(center, center);
        margin-right: 50px;
      }

      .tabs {
        @include custom-flex(center, flex-start);

        .tab {
          @include custom-flex(center, center);
          padding: 8px 12px;
          transition: all 0.3s;

          .text {
            @include f(16, 700, 0, 26, $color-black);
          }

          .partnership-badge {
            @include heading-12-medium($color-white);
            padding: 2px 6px;
            border-radius: 30px;
            margin-left: 4px;
            background-color: $color-primary-700;
          }

          .new {
            @include f($s: 12, $w: 500, $ls: 0, $lh: 16, $c: $color-white);
            padding: 0 6px;
            margin-left: 4px;
            border-radius: 30px;
            background-color: #EB683F;
          }
        }

        .search-icon-wrapper {
          cursor: pointer;
          display: flex;
          margin-right: 18px;
        }

        .hamburger {
          @include custom-flex(center, center);
          cursor: pointer;
        }
      }

      .menu-container {
        @include custom-flex(center, center);

        .menu {
          @include custom-flex(center, center);
          padding: 12px;
          border-radius: 8px;
          margin-right: 24px;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }

          &.active, &:hover {
            background-color: $color-gray-50;

            .arrow-icon {
              transform: rotate(180deg);
            }
          }

          span {
            @include heading-16-bold($color-black);

            &:not(&:last-child) {
              margin-right: 2px;
            }
          }

          .arrow-icon {
            @include custom-flex(center, center);
          }
        }
      }

      .drop-box-toggle {
        cursor: pointer;
        @include custom-flex(center, center);

        .profile {
          @include profile-image(28px);
          margin-right: 8px;
        }

        .down {
          @include custom-flex(center, center);
          margin-left: 8px;
        }
      }

      .drop-box {
        position: absolute;
        z-index: 51;
        right: 64px;
        top: 48px;
        padding: 8px;
        box-sizing: border-box;
        width: 150px;
        border-radius: 4px;

        background-color: white;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);

        &.second {
          right: -32px;
        }

        &.lawyer {
          width: 191px;
          border-radius: 4px;

          .line {
            margin: 8px 0;

            &:after {
              width: 100%;
            }

          }

          .profile-status-wrapper {
            z-index: 51;
            padding: 8px;

            .lawyer-info-box {
              @include custom-flex(center, flex-start);

              .profile-image {
                @include profile-image(34px);
                margin-right: 8px;
              }

              .lawyer-name-link {
                @include custom-flex(flex-start, center, column);

                .lawyer-name {
                  @include btn-16-bold($color-gray-700);
                }

                .go-to-edit-profiles {
                  @include btn-14-medium($color-primary-base);
                  margin-top: 6px;
                }

              }

            }

            .profile-nudge-text {
              @include heading-14-regular($color-gray-700);
              margin: 16px 0 12px;
            }

            .profile-info-complete-status-box {
              @include custom-flex(center, center, $wrap: nowrap);
              margin-bottom: 12px;

              .complete-status-step {
                width: 100%;
                height: 6px;
                background-color: $color-gray-300;

                &.complete {
                  background-color: $color-primary-base;
                }

                &:not(:first-child) {
                  margin-left: 4px;
                }

              }

            }

            .profile-description {
              @include heading-14-regular($color-gray-600);
            }

            .go-to-edit-profiles-btn {
              @include btn-12-bold($color-white);
              @include custom-flex(center, center);
              margin-top: 16px;
              padding: 10px;
              background-color: $color-primary-base;
              border-radius: 2px;
            }

          }

          .drop-content {
            padding: 9px 8px;
          }

        }

        &.profile-not-complete {
          top: 48px;
          right: 48px;
        }

        .logo-wrapper {
          @include custom-flex(center, center);
          @include absolute-width(134px);
          width: 96px;
          height: 48px;
          padding: 0;
          cursor: pointer;
        }

        .drop-content {
          cursor: pointer;
          display: flex;
          padding: 8px;
          align-items: center;
          @include heading-14-medium($color-gray-800);

          &:not(.box):hover {
            background-color: $color-gray-50;
          }

          &.box {
            background-color: $color-primary-base;
            width: 100%;
            border-radius: 2px;
            padding: 11px 0 10px;
            @include btn-12-bold($color-white);
            @include custom-flex(center, center);
          }

          .drop-content-text {
            @include heading-14-medium($color-gray-800);
            display: flex;
          }

          span.new {
            @include f(12, 500, 0, 16, $color-white);
            @include custom-flex(center, center);
            padding: 0 6px;
            margin-left: 4px;
            border-radius: 30px;
            background-color: #EB683F;
            height: 16px;
          }
        }

        .line {
          width: 100%;
          height: 1px;
          margin: 10px 0;
          @include custom-flex(center, center);

          &:after {
            content: '';
            clear: both;
            width: calc(100% - 16px);
            height: 1px;
            background-color: $color-gray-200;
          }
        }
      }

      .transparent {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 50;
      }
    }
  }

  #mobile-navigation {
    &.scrolled > .nav-container {
      background-color: white;
      border-bottom: 1px solid #E8EBED;
    }

    .nav-container {
      z-index: 11;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: $nav-mobile-height;
      background-color: transparent;
      box-sizing: border-box;
      padding: 0 20px;
      transition: background-color 0.3s;
      @include custom-flex(center, flex-start);


      .mobile-nav-icon {
        cursor: pointer;
        @include custom-flex(center, center);
      }

      .mobile-questionnaire-nav, .mobile-board-nav {
        svg {
          stroke: $color-28282D;
        }
      }

      .logo-wrapper {
        @include custom-flex(center, center);
        height: 24px;
        margin-right: auto;
      }

      .mobile-icon-wrapper {
        @include custom-flex(center, start);

        div {
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .inner-nav {
      background-color: white;
      width: calc(100% - 65px);
      max-width: 400px;
      right: 0;
      top: 0;
      height: 100%;
      z-index: 52;
      position: fixed;
      box-sizing: border-box;
      overflow-y: auto;

      .nav-header {
        @include custom-flex(center, space-between);
        box-sizing: border-box;
        padding: 20px 20px 14px;
        width: 100%;

        .logo {
          @include custom-flex(center, center);
        }

        .close-button {
          @include custom-flex(center, center);
          cursor: pointer;
          width: 1.5rem;
        }
      }


      .scroll-div {
        @include custom-flex(flex-start, flex-start, column);
        padding: 18px 20px 70px;
        width: 100%;
        box-sizing: border-box;

        .profile-section {
          @include custom-flex(center, flex-start);
          margin-bottom: 10px;

          .profile-image {
            @include profile-image(48px);
            margin-right: 12px;
          }

          .profile-info {
            .name {
              @include heading-16-semi-bold($color-gray-950);
              @include custom-flex(center, flex-start);

              .partnership-badge {
                @include heading-12-medium(white);
                border-radius: 30px;
                padding: 1px 6px;
                margin-left: 4px;
                background-color: $color-primary-base;
              }
            }

            .profile-edit {
              @include custom-flex(center, flex-start);
              @include heading-14-medium($color-gray-700);
              margin-top: 4px;
              cursor: pointer;
            }
          }
        }

        .list-section {
          width: 100%;
          padding: 11px 0;

          .list-item {
            @include heading-16-semi-bold($color-gray-950);
            @include custom-flex(center, flex-start);
            padding: 11px 0;

            .new {
              @include heading-12-medium(white);
              margin-left: 4px;
              background-color: #E6635E;
              padding: 1px 6px;
              border-radius: 30px;
            }
          }

          .line {
            width: 100%;
            height: 1px;
            background-color: $color-gray-300;
            margin: 11px 0;
          }
        }

        .extra-section {
          .profile-status-wrapper {
            @include custom-flex(flex-start, center, column);
            width: 100%;
            padding: 16px;
            box-sizing: border-box;
            background-color: $color-gray-50;

            .profile-nudge-text {
              @include heading-14-regular($color-gray-700);
              margin-bottom: 12px;
            }

            .profile-info-complete-status-box {
              @include custom-flex(center, center, $wrap: nowrap);
              width: 100%;
              margin-bottom: 12px;

              .complete-status-step {
                width: 100%;
                height: 6px;
                background-color: $color-gray-300;

                &.complete {
                  background-color: $color-primary-base;
                }

                &:not(:first-child) {
                  margin-left: 4px;
                }
              }
            }

            .profile-description {
              @include heading-14-regular($color-gray-600);
            }

            .go-to-edit-profiles-btn {
              @include btn-12-bold($color-white);
              @include custom-flex(center, center);
              width: 100%;
              margin-top: 16px;
              padding: 10px;
              background-color: $color-primary-base;
              border-radius: 2px;
              box-sizing: border-box;
            }
          }
        }

        .forward {
          width: 100%;

          &.client {
            padding-top: 22px;
          }

          .section {
            border-bottom: 1px solid $color-gray-100;
            margin-bottom: 22px;

            .caption {
              @include heading-15-bold($color-gray-600);
              margin-bottom: 16px;
            }

            .link {
              @include heading-16-semi-bold($color-black);
              display: block;
              margin-bottom: 22px;
            }
          }
        }
      }

      .fixed-footer {
        width: calc(100% - 65px);
        max-width: 400px;
        position: fixed;
        bottom: 0;

        .foot-upper {
          width: 100%;
          padding: 12px 20px;
          box-sizing: border-box;

          .backward {
            @include heading-15-semi-bold(white);
            @include custom-flex(center, center);
            width: 100%;
            height: 44px;
            border-radius: 4px;
            background-color: $color-gray-800;
          }
        }

        .foot-row {
          @include custom-flex(flex-start, space-between);
          border-top: 1px solid $color-gray-200;
          background-color: white;
          padding: 8px;

          .left, .right {
            @include custom-flex(flex-start, flex-start);
          }

          a {
            @include heading-14-medium($color-gray-700);
            display: block;
            padding: 8px 12px;
          }
        }
      }
    }

    .black-window {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 11;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .search-popper {
    &.hide {
      display: none;
    }

    position: fixed;
    inset: 61px auto auto 0;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: white;
    padding: 20px 20px 0 20px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);

    .search-box {
      position: relative;

      .search-icon {
        @include custom-flex(center, center);
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
      }

      .clear-icon {
        @include custom-flex(center, center);
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        @include mobile {
          position: unset;
          transform: none;
        }

        &.hide {
          display: none;
        }
      }
    }

    &.hide {
      display: none;
    }

    .search-bar {
      @include heading-16-regular($color-gray-700);
      @include placeholder($color-gray-500);
      width: 376px;
      height: 40px;
      padding: 8px 48px;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid $color-gray-400;

      &::placeholder {
        color: $color-gray-500;
      }

      &:hover,
      &:focus,
      &:active {
        border-color: $color-primary-900;
      }
    }

    .close {
      display: flex;
      justify-content: end;
      margin-left: -20px;
      margin-right: -20px;
      padding: 8px 20px;
      border-top: 1px solid $color-gray-300;

      > div {
        @include heading-14-medium($color-gray-600);
        cursor: pointer;
      }
    }

    .search-result-window {
      @include custom-scrollbar(0);
      margin-left: -20px;
      margin-right: -20px;
      height: 380px;
      padding-top: 20px;
      padding-bottom: 20px;
      box-sizing: border-box;
      overflow-y: scroll;

      & > div {
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }

      .empty-result-wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        .empty-result {
          @include heading-16-medium($color-gray-500);
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .result-wrapper {
        .title {
          @include heading-13-medium($color-gray-600);
          padding-left: 20px;
          margin-bottom: 10px;
        }

        .result-item {
          @include custom-flex(center, space-between, row, nowrap);
          padding: 10px 20px;
          cursor: pointer;

          &:hover {
            background: $color-gray-50;
          }

          .keyword {
            @include heading-16-medium($color-gray-700);
            margin-right: 4px;
            width: 176px;
            flex-shrink: 0;

            b {
              @include heading-16-medium($color-primary-500);
            }
          }

          .arrow {
            margin-right: 4px;
            flex-shrink: 0;
          }

          .category {
            @include heading-16-medium($color-primary-500);
            width: 100%;
            flex-shrink: 1;
          }
        }
      }
    }
  }

  .search-bar-wrapper {
    margin-right: 44px;

    .search-icon {
      @include custom-flex(center, center);
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
    }

    .clear-icon {
      @include custom-flex(center, center);
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      @include mobile {
        position: unset;
        transform: none;
      }

      &.hide {
        display: none;
      }
    }


    .search-result-window {
      @include custom-scrollbar(0);
      background: white;
      position: absolute;
      padding: 20px 0;
      top: 100%;
      left: 0;
      right: 0;
      height: 380px;
      overflow-y: scroll;
      border-radius: 0 0 4px 4px;
      box-shadow: 0 3px 16px hex-to-rgba($color-black, 16%);

      @include mobile {
        display: flex;
        flex-direction: column;
      }

      &.hide {
        display: none;
      }

      & > div {
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }

      .empty-result-wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        .empty-result {
          @include heading-16-medium($color-gray-500);
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .result-wrapper {
        .title {
          @include heading-13-medium($color-gray-600);
          padding-left: 20px;
          margin-bottom: 10px;
        }

        .result-item {
          @include custom-flex(center, space-between, row, nowrap);
          padding: 10px 20px;
          cursor: pointer;

          &:hover {
            background: $color-gray-50;
          }

          .keyword {
            @include heading-16-medium($color-gray-700);
            margin-right: 4px;
            width: 176px;
            flex-shrink: 0;

            b {
              @include heading-16-medium($color-primary-500);
            }
          }

          .arrow {
            margin-right: 4px;
            flex-shrink: 0;
          }

          .category {
            @include heading-16-medium($color-primary-500);
            width: 100%;
            flex-shrink: 1;
          }
        }
      }

      @include mobile {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
        padding: 0;
      }
    }

    &.desktop {
      position: relative;


    }

    &.mobile {
      .search-section {
        position: relative;

        .search-bar {
          @include heading-16-regular($color-gray-700);
          @include placeholder($color-gray-500);
          width: 100%;
          height: 52px;
          padding: 8px 48px;
          box-sizing: border-box;
          height: 52px;
          padding-right: 76px;

          &::placeholder {
            color: $color-gray-500;
          }


          @include mobile {
            width: 100%;
            height: 52px;
            padding-right: 76px;
            border: none;
          }
        }

        .mobile-icon {
          @include custom-flex(center, start, row, nowrap);
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);

          .close-button {
            @include heading-14-medium($color-gray-600);
            cursor: pointer;
            margin-left: 8px;
          }
        }
      }

      .search-result-section {
        margin: 0;
        height: 100%;
        padding: 20px 0;

        & > div {
          margin-top: 20px;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
