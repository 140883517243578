@charset "utf-8";
@import 'definitions';


.footer {
  @include custom-flex(center, center);
  background-color: $color-gray-950;
  box-sizing: border-box;
  padding: 60px 0 70px;

  @include mobile {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .info-wrapper {
    width: 1120px;
    height: 100%;
    @include custom-flex(flex-start, space-between, column, nowrap);

    @include mobile {
      width: 100%;
    }

    .info-1 {
      @include custom-flex(center, space-between);
      width: 100%;
      margin-bottom: 94px;
      @include mobile {
        margin-bottom: 40px;
      }


      a {
        @include body-16-regular($color-gray-200);
      }

      .left-side {
        @include custom-flex(center, start);

        @include mobile {
          @include custom-flex(center, space-between);
          width: 100%;
        }

        a {
          @include mobile {
            display: block;
            width: 50%;
            margin-bottom: 16px;
          }

          &:not(:last-child) {
            margin-right: 40px;
            @include mobile {
              margin-right: 0;
            }
          }
        }
      }

      .right-side {
        .call-center {
          @include body-18-regular($color-gray-200);
        }

        @include mobile {
          margin-top: 80px;
        }
      }
    }

    .info-2 {
      width: 100%;
      padding: 16px 0;
      margin-bottom: 16px;
      border-bottom: 1px solid $color-gray-600;

      .info {
        @include body-14-regular($color-gray-600);
        margin-bottom: 16px;

        &.strong {
          @include body-14-regular($color-gray-200);
          margin-bottom: 8px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .info-3 {
      width: 100%;
      @include custom-flex(flex-start, flex-start, column);

      .info {
        width: 100%;
        @include custom-flex(flex-start, flex-start);

        a {
          @include body-14-regular($color-gray-600);

          &.highlight {
            font-weight: 500;
            color: $color-gray-200
          }
        }


        > * {
          margin-right: 24px;
          @include mobile {
            margin-right: 12px;
          }
        }

        .lawandgood {
          @include body-14-regular($color-gray-600);
        }

        &:first-child {
          margin-bottom: 8px;
        }
      }
    }
  }
}
