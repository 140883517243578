@import './definitions.scss';
@import './fonts.scss';
@import './fight_with_adguard.scss';
@import './ckeditor.scss';

/* Default Settings */
* {
  margin: 0;
  padding: 0;
  font-family: 'Pretendard';
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background-color: $color-white;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
}

ul, ol, li {
  list-style: none;
}

a, a:hover, a:visited {
  text-decoration: none;
  color: inherit;
}

select {
  background-color: $color-white;
}

input:focus, button:focus, select:focus, textarea:focus {
  outline: none;
}

input, textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// 자동완성
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

::-ms-clear {
  display: none !important;
}

::-ms-reveal {
  display: none !important;
}

/* End: Default Settings */


/* start: custom attribute */

.container_to_show {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.dp-none {
  display: none;
}

.dp-block {
  display: block;
}

.vs-hidden {
  visibility: hidden;
}

.stop-scroll {
  overflow: hidden !important;
}

.pointer {
  cursor: pointer;
}

.default-modal {
  @include custom-modal(280px, auto);

  .modal {
    padding: 5px 10px;
  }
}

.select-hide-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';

  &::-ms-expand {
    display: none;
  }
}

.bg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.warning {
  $warning-color: #D93025;

  @include custom-flex(center, center);
  @include font-caption1($warning-color);

  .warning-circle {
    width: 13px;
    height: 13px;
    margin-right: 9px;
  }
}

.imp-dialog {
  background-color: rgba(0, 0, 0, 0.7);
}

// No Drag Class
.no-drag {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-select: none;
  user-drag: none;
}

[data-abtest] {
  display: none !important;

  &[data-variant*='a'] {
    display: initial !important;
    visibility: hidden;

    @include ie {
      display: inherit !important;
    }
  }
}

/* end: custom attribute */


/* media query default */
@media(max-width: $breakpoint-desktop) {
  .desktop {
    display: none !important;

    * {
      display: none !important;
    }
  }
}

@media(min-width: $breakpoint-desktop) {
  .mobile {
    display: none !important;

    * {
      display: none !important;
    }
  }
}

/* end: media query default */

/* start: Swal overwrite */
.swal2-html-container {
  word-break: keep-all;
  word-wrap: break-word;
}

.swal2-container {
  z-index: 100000060 !important;

  .swal2-title {
    font-size: 1.5em;
  }
}

/* end: Swal overwrite */
